import AuthService from '../services/auth.service'

const user = JSON.parse(localStorage.getItem('user'))

const prefs = localStorage.getItem('userPrefs') ? JSON.parse(localStorage.getItem('userPrefs')) : { darkTheme: false }
const initialState = user ? { status: { loggedIn: true }, prefs: prefs, user } : { status: { loggedIn: false }, prefs: prefs, user }

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        userPrefs: (state) => {
            return state.prefs
        },
        writableSensorGroups: (state) => {
            if(!state.user) {
                return []
            }
            else {
                let groups = state.user.groupes

                return groups.filter(x => x.manageSensors).map(x => x.name)
            }
        },
        writableModelsGroups: (state) => {
            if(!state.user) {
                return []
            }
            else {
                let groups = state.user.groupes

                return groups.filter(x => x.manageModels).map(x => x.name)
            }
        },
        writableUserGroups: (state) => {
            if(!state.user) {
                return []
            }
            else {
                let groups = state.user.groupes
    
                return groups.filter(x => x.manageUsers)
            }
        },
        writableGroups: (state) => {
            if(!state.user) {
                return []
            }
            else {
                let groups = state.user.groupes
    
                return groups.filter(x => x.manageGroup)
            }
        },
        canAddSensors: (state) => {
            let canAdd = state.user && (state.user.globalAdmin || state.user.groupes.filter(x => x.manageSensors).length > 0)
            return canAdd
        },
        canManageUsers: (state) => {
            let canAdd = state.user && (state.user.globalAdmin || state.user.groupes.filter(x => x.manageUsers || x.manageGroup).length > 0)
            return canAdd
        },
        canManageModels: (state) => {
            let canAdd = state.user && (state.user.globalAdmin || state.user.groupes.filter(x => x.manageModels).length > 0)
            return canAdd
        },
        isAdmin: (state) => {
            return state.user && state.user.globalAdmin
        },
        canUserAccessBRGM: (state) => {
            return state.user && (state.user.globalAdmin || state.user.groupes)
        },
        canManageBSH: (state) => {
            return state.user && state.user.globalAdmin
        },
        isPremium: (state) => {
            return state.user && state.user.globalAdmin || state.user.groupes.map(x => x.premium).includes(true)
        },
        isPrivileges: (state) => {
            return state.user && state.user.privileged
        }
    },
    actions: {
        login({ commit, dispatch }, { email, password }) {
            return AuthService.login(email, password).then(
                user => {
                    commit('loginSuccess', user)
                    dispatch('refresh')
                    return Promise.resolve(user)
                },
                error => {
                    commit('loginFailure')
                    return Promise.reject(error)
                }
            )
        },
        refresh({ commit }) {
            window.setInterval(function() {
                AuthService.refreshToken().then(auth => {
                    if(auth) {
                        let freshUser = JSON.parse(localStorage.getItem('user'))
                        commit('loginSuccess', freshUser)
                        return Promise.resolve(freshUser)
                    } else {
                        commit('loginFailure')
                        console.warn('Session expired')
                        AuthService.logout()
                        window.location.href = "/?session-expired"
                        return Promise.reject()
                    }
                })
            }, 1000 * 30)
        },
        toggleDarkTheme({ commit }, enabled) {
            prefs.darkTheme = enabled
            localStorage.setItem('userPrefs', JSON.stringify(prefs))
            commit('darkThemeToggled', enabled)
            return Promise.resolve()
        },
        saveMapExtent({ commit }, extent) {
            prefs.mapExtent = extent
            localStorage.setItem('userPrefs', JSON.stringify(prefs))
            commit('mapExtentSaved', extent)
            return Promise.resolve()
        },
        logout({ commit }) {
            AuthService.logout()
            commit('logout')
            return Promise.resolve()
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true
            state.user = user
        },
        loginFailure(state) {
            state.status.loggedIn = false
            state.user = null
        },
        darkThemeToggled(state, enabled) {
            state.prefs.darkTheme = enabled
        },
        mapExtentSaved(state, extent) {
            state.prefs.mapExtent = extent
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
        }
    }
}