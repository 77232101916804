import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1a90ff',
                secondary: '#2196f3',
                accent: '#00bcd4',
                error: '#f44336',
                warning: '#ff9800',
                info: '#607d8b',
                success: '#8bc34a',
                background: '#fff',
                element: '#f5f5f5'
            },
            dark: {
                primary: '#272b30',
                secondary: '#3a4750',
                accent: '#00adb5',
                error: '#f44336',
                warning: '#ff9800',
                info: '#607d8b',
                success: '#8bc34a',
                background: '#0d0f13',
                element: '#38414a'
            }
        }
    }
});
