<template>
  <v-snackbar app v-model="show" multi-line :timeout="timeout" :color="color">
      <div v-html="message"></div>
    <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
export default {
    name: 'SnackBar',
    data () {
        return {
          show: false,
          message: '',
          color: '',
          timeout: 5000
        }
    },

    created () {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'messages/showMessageSuccess') {
                this.message = state.messages.content
                this.color = state.messages.color
                if(state.messages.timeout)
                  this.timeout = state.messages.timeout
                this.show = true
            }
        })
    }
}
</script>