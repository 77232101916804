const initialState = {
    content: null,
    color: null,
    timeout: 5000
}

export const messages = {
    namespaced: true,
    state: initialState,
    actions: {
        showMessage({ commit }, { content, color, timeout }) {
            commit('showMessageSuccess', { content: content, color: color, timeout: timeout})
        }
    },
    mutations: {
        showMessageSuccess (state, payload) {
            state.content = payload.content
            state.color = payload.color
            state.timout = payload.timeout
        }
    }
}
