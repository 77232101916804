<template>
    <v-container>
        <v-row
            align="center"
            justify="center"
            >
            <v-col
                cols="12"
                sm="8"
                md="4"
            >
                <v-form @submit.prevent="logIn()">
                    <v-card class="elevation-12">
                    <v-toolbar
                        color="primary"
                        dark
                    >
                        <v-toolbar-title v-if="!sessionExpired">{{$t('login')}}</v-toolbar-title>
                        <v-toolbar-title v-else><v-icon class="mr-5">mdi-alert-outline</v-icon>{{$t('endSession')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            :rules="[validationRules.required, validationRules.email]"
                            label="Email"
                            name="email"
                            prepend-icon="mdi-email"
                            type="text"
                            v-model="email"
                        ></v-text-field>

                        <v-text-field
                            :rules="[validationRules.required]"
                            id="password"
                            :label="$t('password')"
                            name="password"
                            prepend-icon="mdi-lock"
                            type="password"
                            v-model="password"
                        ></v-text-field>
                        <v-alert dense text :value="message && message.length > 0" transition="scroll-y-transition" type="warning">
                            {{message}}
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn target="_blank" :href="this.resetPasswordUrl"
                            x-small
                            text
                            color="secondary">
                            {{$t('forgetPassword')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" color="primary">{{$t('loginPush')}}</v-btn>
                        <v-btn target="_blank" :href="this.signupUrl"
                            x-small
                            text
                            color="secondary">
                            {{$t('createAccount')}}
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-4">
            <v-col cols="12" sm="8" md="4" align="center" class="caption">
                
                {{$t('clientInformation')}}<br />
                <a href="https://assistance.brgm.fr/page/donnees-personnelles" target="_blank">{{$t('linkHelp')}}</a>
                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'LoginForm',
    data () {
        return {
            email: null,
            password: null,
            loading: false,
            message: null,
            validationRules: {
                required: value => !!value || this.$t("fieldRequiered"),
                email: value => /[^@]+@[^\\.]+\..+/gi.test(value) || this.$t("wrongEmail")
            }
        }
    },
    computed: {
        loggedIn() {
            if (this.user && new Date(this.user.validUntil) > new Date()) {
                return this.$store.state.auth.status.loggedIn;
            } else {
                this.$store.dispatch("auth/logout");
                return false;
            }
        },
        signupUrl() {
            return process.env.VUE_APP_SIGNUP_URL
        },
        resetPasswordUrl() {
            return process.env.VUE_APP_RESET_PASSWORD_URL
        },
        sessionExpired() {
            return window.location.href.indexOf('?session-expired') > 0
        }
    },
    beforeMount() {
        if (this.loggedIn) {
            this.$router.push('/map');
        }
    },
    methods: {
        logIn() {
            this.loading = true
            if(this.email && this.password) {
                this.$store.dispatch('auth/login', { email: this.email, password: this.password })
                    .then(() => {
                        this.$router.push('/map')
                    },
                    // eslint-disable-next-line no-unused-vars
                    error => {
                        this.loading = false
                        this.message = this.$t('errorIdentification')
                    })
            }
        }
    }
}
</script>
<style scoped>

</style>