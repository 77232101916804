import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresLogin: false },
    component: Home
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue')
  },
  {
    path: '/map',
    name: 'Map',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "Map" */ '../views/Map.vue')
  },
  {
    path: '/model/:id',
    name: 'Model',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "Model" */ '../views/Model.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    props: (route) => ({ board: route.params.board || 'default' })
  },
  {
    path: '/decrees',
    name: 'Decrees',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Decrees.vue'),
    props: (route) => ({ board: route.params.board || 'default' })
  },
  {
    path: '/dashboard/:board',
    name: 'DashboardDetails',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    props: (route) => ({ board: route.params.board || 'default' })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresLogin) && !store.state.auth.status.loggedIn) {
    next({ name: 'Home' })
  }
  else {
    next()
  }
})

export default router
