import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMatomo from 'vue-matomo';
import VueI18n from 'vue-i18n'


Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: 'fr',
  messages:loadLocaleMessages(),
})

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueMatomo, {
  host: 'https://wwwstats.brgm.fr',
  siteId: 281,
  trackerFileName: 'piwik',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 5,
  debug: false,
  userId: undefined,
  cookieDomain: '*.meteeaunappes.brgm.fr',
  domains: '*.meteeaunappes.brgm.fr',
  preInitActions: []
})


