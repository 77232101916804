export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'))

    if(user && user.token) {
        let header = { Authorization: `Bearer ${user.token}` }
        header['Content-Type'] = 'application/json; charset=utf-8'
            
        return header
    } else {
        return {}
    }
}