export default class Groupe {
    constructor(id, name, description, premium, manageModels, manageSensors, manageUsers, manageGroup) {
        this.id = id
        this.name = name
        this.description = description
        this.premium = premium
        this.manageModels = manageModels
        this.manageSensors = manageSensors
        this.manageUsers = manageUsers
        this.manageGroup = manageGroup
    }
}