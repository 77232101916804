export default class User {
    constructor(token, validUntil, email, nom, prenom, globalAdmin, privileged) {
        this.token = token
        this.validUntil = validUntil
        this.email = email
        this.nom = nom
        this.prenom = prenom
        this.globalAdmin = globalAdmin
        this.privileged = privileged
        this.groupes = []
    }
}