import User from '../models/userModel'
import Groupe from '../models/groupeModel'
import authHeader from './auth-header'

const API_BASE = process.env.VUE_APP_API_URL

class AuthService {
    async login(email, password) {
        let response = await fetch(`${API_BASE}/Authorize/authenticate`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email, password: password })
        })

        if(response.ok) {
            let data = await response.json()
            let user = this.parseToken(data)

            localStorage.setItem('user', JSON.stringify(user))

            return user
        } else {
            throw new Error(response.statusText)
        }
    }

    async refreshToken() {
        let response = await fetch(`${API_BASE}/authorize/refreshtoken`, {
            method: 'GET',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            let data = await response.json()
            let user = this.parseToken(data)

            localStorage.setItem('user', JSON.stringify(user))

            return true
        } else {
            this.logout()
            return false
        }
    }

    logout() {
      localStorage.removeItem('user');
    }

    parseToken(data) {
        let token = data.token
        try {
            let parsed = JSON.parse(atob(token.split('.')[1]))
            let deserializedGroups = JSON.parse(parsed.role)
            
            let user = new User(token, new Date(data.validUntil), parsed.email, parsed.unique_name, parsed.given_name, (/true/i).test(parsed.global_admin), (/true/i).test(parsed.privileged))
            deserializedGroups.forEach(g => {
                let group = new Groupe(g.Id, g.Groupe.Nom, g.Groupe.Description, g.Groupe.Premium, g.ManageGroupModels, g.ManageGroupSensors, g.ManageGroupUsers, g.ManageOwnGroup)
                user.groupes.push(group)
            });

            return user
        } catch (e) {
            return null
        }
    }

    async getGroups() {
        let response = await fetch(`${API_BASE}/authorize/groupes`, {
            method: 'GET',
            mode: 'cors',
            headers: authHeader()
        })

        if(response.ok) {
            let data = await response.json()
            return data
        } else {
            return []
        }
    }
}

export default new AuthService();