<template>
  <v-app>
    <v-navigation-drawer
      class="element"
      app
      right
      expand-on-hover
      v-model="drawer"
      :mini-variant.sync="sync"
      v-if="currentRoute !== 'Home' && loggedIn"
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="true && 'px-0'">
          <v-list-item-avatar color="accent" v-html="avatarName"></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ this.user.prenom }} {{ this.user.nom }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.user.groupes.filter(g => g.name !== 'Public').map(g => g.name).join(', ') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/map" v-if="currentRoute !== 'Map'">
          <v-list-item-icon>
            <v-icon>mdi-map</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t("backMap")}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-else>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-layers</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('displayLayers')}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-group multiple v-model="selectedLayers" v-on:change="toggleLayer">
              <v-list-item v-for="(l, i) in filteredLayers" v-bind:key="i" :value="l.name">
                <template v-slot:default="{ active }">    
                  <v-list-item-icon>
                    <v-icon>{{l.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t(l.title)}}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 0.7em">{{$t(l.subtitle)}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
        </template>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item link to="/decrees" v-if="canUserAccessBRGM">
            <v-list-item-icon>
              <v-icon>mdi-town-hall</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('Decrees')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/dashboard" v-if="canUserAccessDashboard">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t('params')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="toggleTheme">
            <v-list-item-icon>
              <template v-if="!isDarkTheme">
                <v-icon>mdi-circle-half-full</v-icon>
              </template>
              <template v-else>
                <v-icon>mdi-white-balance-sunny</v-icon>
              </template>
            </v-list-item-icon>
            <v-list-item-content>
              <template v-if="!isDarkTheme">
                <v-list-item-title>{{$t('dark')}}</v-list-item-title>
              </template>
              <template v-else>
                <v-list-item-title>{{$t('light')}}</v-list-item-title>
              </template>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/logout">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t("disc")}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar app color="primary" absolute>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 hidden-md-and-up"
          contain
          :src="images.logo"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="MétéEAU Nappes"
          class="shrink hidden-sm-and-down"
          contain
          min-width="150"
          :src="images.text"
          height="40"
        />

        <div style="position:absolute;right: 0">
          <v-btn style="marging: 3px;"
            icon
            @click="$i18n.locale = 'fr'"
          >
            <v-img :src="images.flagFr"   max-height="30" max-width="30"></v-img>
          </v-btn>
          <v-btn style="marging: 3px;"
            icon
            @click="$i18n.locale = 'en'"
          >
          <v-img :src="images.flagEn"   max-height="30" max-width="30"></v-img>

        </v-btn>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
      <SnackBar />
    </v-main>
    <v-footer>
      <a href="https://www.brgm.fr" target="_blank">
        <v-img
          alt="BRGM"
          contain
          :src="images.logoBrgm"
          width="175"
          height="60"
        />
      </a>
    </v-footer>
  </v-app>
</template>

<script>
import SnackBar from '@/components/SnackBar.vue'

export default {
  name: "App",

  components: { SnackBar },
  data() {
    return {
      drawer: true,
      sync: true,
      layers: [
        { name: 'modelPiezos', title:"models", subtitle: "modelPiezo", icon: 'mdi-cloud-braces' },
        { name: 'piezos', title:"piezo", subtitle: "allPiezo", icon: 'mdi-triangle-outline' },
        { name: 'pluvios', title: "pluvios", subtitle: "postSynop", icon: 'mdi-water' },
        { name: 'hydro', title: "hydro", subtitle: "postHydro", icon: 'mdi-checkbox-blank-circle-outline' },
        { name: 'decrees', title:"Decrees", subtitle: "postDecrees", icon: 'mdi-alert' },
      ],
      selectedLayers: ['modelPiezos'],
      images: {
        logo: require('@/assets/icono.svg'),
        flagFr :require('@/assets/flagFr.svg'),
        flagEn :require('@/assets/flagEn.svg'),
        text: require('@/assets/logo.svg'),
        logoBrgm: require('@/assets/logo-brgm.svg')
      }
    };
  },
  mounted() {
    this.$vuetify.theme.dark = this.isDarkTheme;

    if (this.loggedIn) {
      this.$store.dispatch("auth/refresh");
      this.checkAuthentication()
    } else {
      this.$store.dispatch("auth/logout").then(() => {
        if(this.currentRoute !== "Home") { 
          this.$router.push("/");
        }
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    canUserAccessBRGM() {
      return this.user.groupes.some(g => g.name === 'BRGM');
    },
    canUserAccessDashboard() {
      return this.user && (this.user.globalAdmin || this.user.groupes.map(x => x.manageModels).includes(true) || this.user.groupes.map(x => x.manageSensors).includes(true) || this.user.groupes.map(x => x.manageUsers).includes(true) || this.user.groupes.map(x => x.manageGroup).includes(true))
    },
    filteredLayers() {
    if (this.canUserAccessBRGM) {
      return this.layers;
    } else {
      return this.layers.filter(l => l.name !== 'decrees');
    }
  },
    isDarkTheme() {
      if(!this.$store.state.auth && !this.$store.state.auth.prefs) 
        return false
      else
        return this.$store.state.auth.prefs.darkTheme
    },
    loggedIn() {
      if (this.user && new Date(this.user.validUntil) > new Date()) {
        return this.$store.state.auth.status.loggedIn;
      } else {
        this.$store.dispatch("auth/logout");
        return false;
      }
    },
    avatarName() {
      let initials = (this.user.prenom + " " + this.user.nom)
        .match(/\b[a-zA-Z]/g)
        .join("");

      return initials;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  watch: {
    currentRoute() {
      this.selectedLayers = ['modelPiezos']
    }
  },
  methods: {
    toggleTheme() {
      this.$store
        .dispatch("auth/toggleDarkTheme", !this.isDarkTheme)
        .then(() => {
          this.$vuetify.theme.dark = !!this.isDarkTheme;
        });
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    checkAuthentication() {
      const router = this.$router
      const loggedIn = this.loggedIn
      setInterval(function() {
        if(!loggedIn) {
          router.push('Home')
          console.warn('Authentication expired.')
        }
      }, 5000)
    },
    toggleLayer() {
      this.layers.forEach(l => {
        this.$store.dispatch("modelsmap/toggleLayer", { layerName: l.name, visible: false })
      })
      this.selectedLayers.forEach(layer => {
        this.$store.dispatch("modelsmap/toggleLayer", { layerName: layer, visible: true })
      })
    }
  },
};
</script>
<style>
.v-application .theme--dark.primary--text {
    color: #c9c9c9 !important
}
div.row {
    margin: 0 !important;
    margin-right: -12px !important;
    margin-left: -12px !important;
}
</style>