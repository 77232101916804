<template>
  <v-container class="fill-height" fluid>
    <LoginForm />
  </v-container>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/LoginForm'

export default {
  name: 'Home',
  components: {
    LoginForm
  }
}
</script>
