const initialState = {
    layerName: '',
    visible: false
}

export const modelsmap = {
    namespaced: true,
    state: initialState,
    actions: {
        toggleLayer({ commit }, { layerName, visible }) {
            commit('toggleLayerSuccess', { layerName: layerName, visible: visible })
        }
    },
    mutations: {
        toggleLayerSuccess (state, payload) {
            state.layerName = payload.layerName
            state.visible = payload.visible
        }
    }
}
